<template>
  <div>
    <div v-if="districts && districts.length > 0">
      <v-tabs class="tabs--district" v-model="tab" center-active>
        <v-tab
          v-for="listingType in listingTypes"
          :key="`v-tab-${listingType}`"
          :href="`#tab-${listingType}`"
          class="tab--content"
        >
          {{ $t(`directory.district.${listingType}`) }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" touchless class="mt-2">
        <v-tab-item
          v-for="listingType in listingTypes"
          :key="`v-tab-item-${listingType}`"
          :value="`tab-${listingType}`"
        >
          <div class="row ma-0">
            <div class="col-6 pa-1" v-for="district in districts" :key="district.id">
              >
              <router-link :to="districtPath(listingType, district)">{{
                district.name
              }}</router-link>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  components: {},
  data() {
    return {
      tab: 'tab-' + (this.listingTypes ? this.listingTypes[0] : ''),
    };
  },
  computed: {
    ...mapState({
      listingTypes: (state) => state.global.listingTypes,
      districts: (state) => state.v2.directory.districts,
    }),
  },
  methods: {
    districtPath(listingType, district) {
      let propertyType = this.$route.params.propertyType;
      if (propertyType === 'coworkingspace') propertyType = 'Co-working Space';
      if (district && district.name) {
        return encodeURI(
          '/' +
            (listingType === 'R' ? 'sewa' : 'beli') +
            '/search/' +
            propertyType +
            '/' +
            district.name.replace('-', '__').split(' ').join('-').replace(/\//g, '') +
            '?category=city&category_filter_id=' +
            district.id,
        );
      } else {
        return encodeURI('/' + (listingType === 'R' ? 'sewa' : 'beli') + '/search/' + propertyType);
      }
    },
  },
};
</script>
